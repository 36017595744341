import styled from 'styled-components';

export const Container = styled.button`
  background: ${({ theme }) => theme.color.accent_dark};
  color: ${({ theme }) => theme.color.text_on_primary};
  padding: 0 1rem;
  display: flex;
  border: none;
  align-items: center;
  position: relative;
  min-height: 32px;
  cursor: pointer;
`;

export const ActiveOption = styled.p`
  margin: 0;
  margin-right: 1rem;
`;

export const List = styled.ul`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin: 0;
  min-width: 250px;
  text-align: left;
  position: absolute;
  top: 32px;
  right: 0;

  li {
    margin: 0;
    padding: calc(0.75rem / 2) 1rem;
    color: ${({ theme }) => theme.color.text_on_primary};
    background: ${({ theme }) => theme.color.accent_dark};

    &:hover {
      background: ${({ theme }) => theme.color.text_on_primary};
      color: ${({ theme }) => theme.color.accent_dark};
      transition: 250ms ease-in-out;
    }
  }
`;

/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import * as Styles from './styles';

const Select = ({ onChange, options, active, placeholder, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const _handleChange = useCallback((value) => onChange(value), []);

  return (
    <Styles.Container onClick={() => setIsOpen((open) => !open)}>
      <Styles.ActiveOption>
        {active && active.label ? active.label : placeholder}
      </Styles.ActiveOption>
      <Styles.List isOpen={isOpen}>
        {options.map((option) => (
          <li
            key={option.value}
            tabIndex="0"
            onClick={() => _handleChange(option.value)}
          >
            {option.label}
          </li>
        ))}
      </Styles.List>
      {icon || <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />}
    </Styles.Container>
  );
};

export default memo(Select);

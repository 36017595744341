/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

import PageTitle from '../page-title';
import ContainerWrapper from '../container';
import { Container as ContainerSelect } from '../select/styles';

export const Header = styled(PageTitle)`
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    margin-bottom: 0;
  }
`;

export const Container = styled(ContainerWrapper)`
  ${ContainerSelect} {
    margin-left: auto;

    @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
      margin-left: inherit;
    }
  }
`;

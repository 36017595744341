import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'html-react-parser';
import { decodeHtml } from '../helpers';

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0.5;
  font-size: 1rem;
  line-height: 1.5rem;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.35);
  background-color: ${({ theme }) => theme.colors.white};

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    width: 80%;
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 4rem 2rem;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.min}) {
    padding: 2rem 2rem;
  }

  a {
    color: ${({ theme }) => theme.colors.primary_dark};
  }
`;

function ContentRenderer(props) {
  const { content, children } = props;

  return (
    <Container>
      {ReactHtmlParser(decodeHtml(content))}
      {children}
    </Container>
  );
}

ContentRenderer.propTypes = {
  content: PropTypes.string.isRequired,
};

export default ContentRenderer;

import styled, { css } from 'styled-components';

export const Container = styled.button`
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 15px;
  transition: 250ms ease-in-out;
  width: 45px;
  height: 45px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;

  svg {
    color: white;
  }

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary_dark};
  }
`;

import React, { useCallback, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import * as Styles from './styles';

const Anchor = ({ isVisible }) => {
  const onClick = useCallback(
    () =>
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      }),
    [],
  );

  return (
    <Styles.Container isVisible={isVisible} onClick={onClick}>
      <FontAwesomeIcon icon={faAngleUp} />
    </Styles.Container>
  );
};

export default memo(Anchor);

import React, { useLayoutEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import FlexDiv from '../flex-div';
import Anchor from '../anchor';
import ContentRenderer from '../content-renderer';
import Article from '../article';
import SEO from '../seo';
import Select from '../select';

import { Header, Container } from './styles';

import { decodeHtml } from '../../helpers';

const FIXED_HEADER_HEIGHT = 120;
const ANCHOR_MIN_THREESHOLD = 500;

function PageRenderer(props) {
  const { title, content } = props;
  const [navOptions, setNavOptions] = useState([]);
  const [isAnchorVisible, setIsAnchorVisible] = useState(false);

  const trackWindowScroll = useCallback(() => {
    const footerOffset = document.querySelector('footer').offsetTop - 1000;
    const { pageYOffset } = window;
    setIsAnchorVisible(
      pageYOffset > ANCHOR_MIN_THREESHOLD && pageYOffset < footerOffset,
    );
  }, [setIsAnchorVisible]);

  useLayoutEffect(() => {
    const topics = Array.from(
      document.querySelectorAll('.sci-submenu'),
    ).map((topic) => ({
      label: topic.textContent,
      value: topic.textContent,
      element: topic,
    }));

    setNavOptions(topics);
    window.addEventListener('scroll', trackWindowScroll);

    return () => {
      window.removeEventListener('scroll', trackWindowScroll);
    };
  }, []);

  const handleMenuChange = useCallback(
    (value) => {
      const option = navOptions.find((opt) => opt.value === value);
      if (!option) return;

      window.scrollTo({
        top: option.element.offsetTop - FIXED_HEADER_HEIGHT,
        behavior: 'smooth',
      });
    },
    [navOptions],
  );

  return (
    <>
      <SEO title={decodeHtml(title)} />
      <Article>
        <Container maxWidth="972px" marginBottom="2.5rem">
          <FlexDiv
            flexDirection={['column', 'column', 'row']}
            alignItems={['flex-start', 'flex-start', 'center']}
            justifyContent={['flex-start', 'flex-start', 'space-between']}
          >
            <Header>{decodeHtml(title)}</Header>
            {navOptions.length > 0 && (
              <Select
                options={navOptions}
                active={null}
                placeholder="Menu"
                onChange={handleMenuChange}
                icon={<FontAwesomeIcon icon={faBars} />}
              />
            )}
          </FlexDiv>
        </Container>
        <ContentRenderer content={content} />
      </Article>
      <Anchor isVisible={isAnchorVisible} />
    </>
  );
}

PageRenderer.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default PageRenderer;
